<script setup lang="ts">
import { VDialog } from "vuetify/components/VDialog";

interface Props {
  transition?: string;
  width?: string | number;
  maxWidth?: string | number;
  persistent?: boolean;
  fullscreen?: boolean;
  eager?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  transition: undefined,
  width: undefined,
  maxWidth: 1200,
  persistent: undefined,
  fullscreen: undefined,
  eager: false,
});

const toggle = defineModel<boolean>({
  required: false,
});
</script>

<template>
  <VDialog
    v-bind="{
      ...props,
      maxWidth: props.fullscreen ? undefined : props.maxWidth,
    }"
    v-model="toggle"
  >
    <template #activator="{ props: slotProps }">
      <slot
        name="activator"
        v-bind="{ props: slotProps }"
      />
    </template>
    <slot :close="() => (toggle = false)" />
  </VDialog>
</template>
